const ZoneInMap = [
    {
        id:1,
        region:'Metropolitana',
        comuna:'Maipú',
        longitude:-70.84994341680652,
        latitude:-33.50409734612103
    },
    {
        id:1,
        region:'Metropolitana',
        comuna:'Renca',
        longitude:-70.72560920991708,
        latitude:-33.40217018668683,
    },
  ]


  export default ZoneInMap;